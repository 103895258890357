<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";

import {mapState} from "vuex";

import {
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import {get_acsrf_token, with_acrf_token} from "@/methods";
import {HalfCircleSpinner} from "epic-spinners";

export default {
  page: {
    title: "Support",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    Multiselect,
	  HalfCircleSpinner,
    SupportStatus: () => import('@/components/lazy/support/SupportStatus'),
  },
  methods: {
    checkHoliday() {
      // TODO: Convert to API based check
      let isHoliday = false;
      let date = new Date();
      if(date.getMonth() === 11 && date.getDate() > 23) isHoliday = true; // Christmas
      else if(date.getMonth() === 0 && date.getDate() < 6) isHoliday = true; // New year
      else if(date.getMonth() === 4 && date.getDate() === 1) isHoliday = true; // Tag der Arbeit
      else if(date.getMonth() === 9 && date.getDate() === 3) isHoliday = true; // Tag der deutschen Einheit
      else if(date.getMonth() === 10 && date.getDate() === 1) isHoliday = true; // 1 Nov
      if(isHoliday) {
        this.holiday = true;
      }
    },
    selectSection(section) {
      this.section = section;
      this.category = null;
      if(this.section !== 3) this.categoryRequired = true;
      else this.categoryRequired = false;
    },
    filePrompt() {
      document.getElementById('file').click();
    },
    addFile(event) {
      let toast = this.$toast;
      let files = event.target.files;
      files.forEach((file) => {
        if(this.files.length >= 5) {
          this.$toast.error(this.$t('support.request.upload.errors.count', {file: file.name}));
          return;
        }
        if(this.files.filter((e)=>{if(e.name === file.name) return e;}).length !== 0) return;
        let actualFileSize = file.size / 1024 / 1024; // MiB
        if (actualFileSize > 10) {
          this.$toast.error(this.$t('support.request.upload.errors.size', {file: file.name}));
        } else {
          this.files.push({
            object: file,
            size: actualFileSize.toPrecision(2),
            name: file.name,
            processing: false,
            error: false,
            uploaded: false
          })
        }

      });
      this.$refs.file.value = null;
    },
    removeFile(fileName) {
      let index = this.files.map((e) => {return e.name;}).indexOf(fileName);
      if(index !== -1) this.files.splice(index, 1);
    },
    afterRequestSubmitted(_ticket_id) {
      let ticket_id = _ticket_id;

      this.files.forEach((_file) => {
        let file = _file;
        if(file.uploaded) return;
        file.processing = true;
        with_acrf_token().then((r) => {
          let payload = {
            acsrf_token: r.acsrf_token,
            ticket_id: ticket_id
          };
          fetch(process.env.VUE_APP_ROOT_API + 'v1/support/attachment', {
            method: 'POST',
            body: JSON.stringify(payload),
            credentials: 'include'
          })
              .then(response => {
                if (response.ok) {
                  return response.json();
                } else {
                  file.processing = false;
                  file.error = true;
                  if (response.status == 429) {
                    this.$toast.warning(this.$t('error.server.ratelimit.title'));
                  } else
                    throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
                }
              })
              .then(data => {
                let upload_url = data.upload_url;
                let upload_token = data.upload_token;

                let resource_id = data.resource_id;

                let payload = new FormData();
                payload.append('file', file.object);
                payload.append('upload_token', upload_token);

                fetch(upload_url, {
                  method: 'POST',
                  body: payload,
                  credentials: 'include'
                })
                    .then(response => {
                      if (response.ok) {
                        return response.json();
                      } else {
                        throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
                      }
                    })
                    .then(data => {
                      let resource_url = data.resource.url;

                      with_acrf_token().then((r) => {
                        let payload = {
                          acsrf_token: r.acsrf_token,
                          resource_id: resource_id,
                          ticket_id: ticket_id,
                          filename: file.name
                        };
                        fetch(process.env.VUE_APP_ROOT_API + 'v1/support/register-attachment', {
                          method: 'POST',
                          body: JSON.stringify(payload),
                          credentials: 'include'
                        })
                            .then(response => {
                              if (response.ok || response.status === 400) {
                                return response.json();
                              } else {
                                if (response.status === 429) {
                                  this.$toast.warning(this.$t('error.server.ratelimit.title'));

                                } else
                                  throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
                              }
                            })
                            .then(data => {
                              if (data.status) {
                                file.error = false;
                                file.processing = false;
                                file.uploaded = true;
                              } else {
                                file.error = true;
                                file.processing = false;
                              }
                            })
                            .catch(error => {
                              console.log(`[ERROR] ${error}`);
                              file.error = true;
                              file.processing = false;
                            });
                      }).catch(error => {
                        console.log('[ERROR] Failed to request acrf token');
                        this.$toast.error(this.$t('error.server.generic.message'));
                        file.error = true;
                        file.processing = false;
                      });
                    })
                    .catch(error => {
                      console.log(`[ERROR] ${error}`);
                      this.$toast.error(this.$t('error.server.generic.message'));
                      file.error = true;
                      file.processing = false;
                    });

              })
              .catch(error => {
                console.log(`[ERROR] ${error}`);
                file.error = true;
                file.processing = false;
              });
        });
      });
      this.$swal({
        icon: 'success',
        text: this.$t('support.request.success')
      }).then(() => {
        this.$router.push(`/support/${ticket_id}`);
      });
    },
    async submitRequest() {
			this.submitted = true;
      if(this.request_submitted) {
        if(this.request_ticket_id && this.request_failed_files) {
          this.afterRequestSubmitted(this.request_ticket_id);
        }
        return;
      }
      this.$v.$touch();
      if(this.$v.request.$error) return;

      let section = this.section;
      let category = this.category ? this.category.id : null;
      let content = this.request.content;
      let reference = this.request.reference;

	    try {
		    let acsrf_token = await get_acsrf_token();
		    let payload = {
			    acsrf_token: acsrf_token,
			    section: section,
			    category: category || 0,
			    content: content,
			    reference: reference || ''
		    };
		    let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/support/open`, {
			    method: 'POST',
			    body: JSON.stringify(payload),
			    credentials: 'include'
		    });
		    if (response.ok) {
					let data = await response.json();
			    this.request_submitted = true;
			    this.request_ticket_id = data.ticket_id;
			    this.afterRequestSubmitted(data.ticket_id);
		    } else {
			    if (response.status === 429) {
				    this.$swal({
					    icon: 'warning',
					    text: this.$t('error.server.ratelimit.message'),
					    title: this.$t('error.server.ratelimit.title')
				    });
			    } else
				    throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
		    }
	    } catch (error) {
		    console.log(`[ERROR] ${error}`);
		    this.$swal({
			    icon: 'error',
			    text: this.$t('support.request.errors.generic')
		    });
				this.submitted = false;
	    }

			/*
      let ref = this;
      with_acrf_token().then((r) => {
        let payload = {
          acsrf_token: r.acsrf_token,
          section: section,
          category: category || 0,
          content: content,
          reference: reference || ''
        };
        fetch(process.env.VUE_APP_ROOT_API + 'v1/support/open', {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        })
            .then(response => {
              if(response.ok || response.status === 400){
                return response.json();
              } else {
                if(response.status === 429) {
                  this.$swal({
                    icon: 'warning',
                    text: this.$t('error.server.ratelimit.message'),
                    title: this.$t('error.server.ratelimit.title')
                  });
                } else
                  throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
              }
            })
            .then(data => {
              if(!data) return;
              if(data.status) {
                this.request_submitted = true;
                this.request_ticket_id = data.ticket_id;
                ref.afterRequestSubmitted(data.ticket_id);
              } else {
                this.$swal({
                  icon: 'error',
                  text: this.$t('support.request.errors.generic')
                });
              }
            })
            .catch(error => {
              console.log(`[ERROR] ${error}`);
              this.$swal({
                icon: 'error',
                text: this.$t('support.request.errors.server')
              });
            });
      }).catch(error => {
        console.log('[ERROR] Failed to request acrf token');
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      });
			*/
    }
  },
  validations: {
    request: {
      content: {
        required: required,
        minLength: minLength(40),
        maxLength: maxLength(10000)
      }
    }
  },
  created() {
    this.checkHoliday();
  },
  data() {
    return {
      section: false,
      category: null,
      categoryRequired: null,
      disclaimer: true,
      holiday: false,
      businessHours: {
        start: "10:00",
        end: "16:00"
      },
      businessDays: {
        start: "Monday",
        end: "Friday"
      },
      categories: {
        1: [
          {id: 2, text: 'support.category.technical.cloud'},
          {id: 3, text: 'support.category.technical.server'},
          {id: 6, text: 'support.category.technical.api'},
          {id: 0, text: 'support.category.technical.other'},
        ],
        2: [
          {id: 1, text: 'support.category.billing.invoices'},
          {id: 1, text: 'support.category.billing.provider'},
          {id: 1, text: 'support.category.billing.other'},
        ]
      },
      request: {
        reference: null,
        content: null
      },
	    submitted: false,
      request_submitted: false,
      request_ticket_id: null,
      request_failed_files: false,
      files: [

      ]
    }
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex align-items-center justify-content-between">
      <h4 class="mb-0 font-size-18">
        Support
        <router-link to="/support" class="small ml-2">
          <i class="fas fa-backspace"></i> Go back
        </router-link>
      </h4>
      <div class="page-title-right bv-d-xs-down-none">
        <ol class="breadcrumb m-0">
          <li class="breadcrumb-item">
            <router-link to="/">
              CFTools Cloud
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <span aria-current="location">
              Support
            </span>
          </li>
          <li class="breadcrumb-item active">
            <span aria-current="location">
              Open
            </span>
          </li>
        </ol>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-sm-12">
        <div class="card">
          <div class="card-body">
            <h4>{{ $t('support.title') }}</h4>
            <hr>
            <div class="row justify-content-center">
              <div class="col-lg-3 col-sm-12">
                <div class="btn btn-outline-info btn-block" v-bind:class="{active: section === 1}" v-on:click="selectSection(1)">
                  {{ $t('support.section.technical') }}
                </div>
              </div>
              <div class="col-lg-3 col-sm-12">
                <div class="btn btn-outline-info btn-block" v-bind:class="{active: section === 2}" v-on:click="selectSection(2)">
                  {{ $t('support.section.billing') }}
                </div>
              </div>
              <div class="col-lg-3 col-sm-12">
                <div class="btn btn-outline-info btn-block" v-bind:class="{active: section === 3}" v-on:click="selectSection(3)">
                  {{ $t('support.section.other') }}
                </div>
              </div>
            </div>
            <template v-if="section !== false">
              <hr>
              <div v-if="categoryRequired" class="row justify-content-center">
                <div class="col-lg-9 col-sm-12">
                  <multiselect
                      v-model="category"
                      label="id"
                      track-by="id"
                      :options="categories[section]"
                      :show-labels="false"
                      :allow-empty="false"
                  >
                    <template
                        slot="singleLabel"
                        slot-scope="props"
                    >
                      <div>
                        <span class="option__desc d-inline ml-2">
                          <span class="option__title align-middle">
                            {{ $t(props.option.text) }}
                          </span>
                        </span>
                      </div>
                    </template>
                    <template
                        slot="option"
                        slot-scope="props"
                    >
                      <div>
                        <div class="option__desc d-inline ml-2">
                          <span class="option__title align-middle">
                            {{ $t(props.option.text) }}
                          </span>
                        </div>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>
              <template v-if="!categoryRequired || categoryRequired && category">
                <hr v-if="categoryRequired">
                <div class="row" v-if="section === 1">
                  <div class="col-lg-4 col-sm-12">
                    <div class="form-group">
                      <label for="requestReferenceObject">
                        {{$t('support.request.reference.label')}}
                      </label>
                      <input
                          id="requestReferenceObject"
                          v-model="request.reference"
                          type="text"
                          class="form-control"
                          :placeholder="$t('support.request.reference.placeholder')"
                          value=""
                          :class="{ 'is-invalid': null }"
                      />
                      <div v-if="true === false" class="invalid-feedback">
                        <span v-if="!$v.form.fname.required">This value is required.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-sm-12">
                    <div class="form-group">
                      <textarea
                          id="requestContent"
                          v-model="request.content"
                          rows="5"
                          class="form-control"
                          :placeholder="$t('support.request.content.placeholder')"
                          :class="{ 'is-invalid': $v.request.$error }"
                      />
                      <div v-if="$v.request.$error" class="invalid-feedback">
                        <span v-if="!$v.request.content.required">
                          {{ $t('support.request.content.errors.required') }}
                        </span>
                        <span v-if="!$v.request.content.minLength">
                          {{ $t('support.request.content.errors.minLength') }}
                        </span>
                        <span v-if="!$v.request.content.maxLength">
                          {{ $t('support.request.content.errors.maxLength') }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-sm-12">
                    <input type="file" multiple v-on:change="addFile($event)" id="file" style="display: none;" ref="file">
                    <div class="btn btn-dark btn-block btn-xl" v-on:click="filePrompt()">
                      <h4 class="m-0 text-black-50">
                        <i class="fad fa-cloud-upload"></i> {{ $t('support.request.upload.title') }}
                        <p class="m-0">
                          <small class="text-uppercase font-size-11">
                            {{ $t('support.request.upload.details') }}
                          </small>
                        </p>
                      </h4>
                    </div>
                    <ul class="ml-0 pl-0 mt-2" style="list-style: none;">
                      <template v-if="files.length === 0">
                        <hr>
                      </template>
                      <li v-for="file in files" v-bind:key="file.name">
                        <i class="fas fa-times-hexagon text-danger font-size-16" style="cursor: pointer;" v-on:click="removeFile(file.name)"></i>
                        <span class="ml-2">
                          {{ file.name }}
                          <template v-if="!file.error">
                            <span v-if="!file.uploaded" class="badge badge-info">{{ file.size }} MiB</span>
                            <span v-else-if="file.uploaded" class="badge badge-success"><i class="fas fa-check"></i></span>
                          </template>
                          <span v-else-if="file.error" class="badge badge-danger">{{ $t('support.request.upload.errors.upload') }}</span>
                        </span>
                        <i v-if="file.processing" class="fas fa-spinner fa-spin ml-1"></i>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <button class="btn btn-block btn-primary btn-lg text-uppercase" v-on:click="submitRequest" :disabled="submitted" :class="{'disabled': submitted}">
	                    <template v-if="submitted">
		                    <half-circle-spinner v-if="submitted"
		                                   :animation-duration="1200"
		                                   :size="24"
		                                   class="align-middle d-inline-block"
		                    />
	                    </template>
	                    <template v-else>
		                    Submit support request
	                    </template>
                    </button>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <SupportStatus/>

        <!--
        <template v-if="disclaimer">
          <div v-if="!holiday" class="row">
            <div class="col-lg-12">
              <div class="card text-white-50 bg-info">
                <div class="card-body p-2">
                  <h5 class="mt-0 mb-2 text-white font-size-20">
                    <i class="fad fa-info-circle mr-1"></i> {{ $t('support.disclaimer.default.title') }}
                  </h5>
                  <p class="card-text text-white">
                    {{ $t('support.disclaimer.default.message_start') }}
                    <b class="ml-1">{{ businessHours.start }} - {{ businessHours.end }}</b> (CET) <b class="ml-2">{{ businessDays.start }} - {{ businessDays.end }}</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="holiday" class="row">
            <div class="col-lg-12">
              <div class="card text-white-50 bg-warning">
                <div class="card-body p-2">
                  <h5 class="mt-0 mb-2 text-white font-size-20">
                    <i class="fad fa-info-circle mr-1"></i> {{ $t('support.disclaimer.holiday.title') }}
                  </h5>
                  <p class="card-text text-white">
                    <u>{{ $t('support.disclaimer.holiday.message') }}</u>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
        -->
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <h5 class="text-uppercase">{{$t('support.hotlinks.status.title')}}</h5>
                {{$t('support.hotlinks.status.message')}}
                <a href="https://status.cftools.cloud" target="_blank" class="btn btn-light btn-block mt-4">
                  <i class="fad fa-external-link"></i> {{$t('support.hotlinks.status.link')}}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <h5 class="text-uppercase">{{$t('support.hotlinks.docs.title')}}</h5>
                {{$t('support.hotlinks.docs.message')}}
                <a href="https://docs.cftools.cloud" target="_blank" class="btn btn-light btn-block mt-4">
                  <i class="fad fa-external-link"></i> {{$t('support.hotlinks.docs.link')}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>